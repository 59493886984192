import axios from 'axios'
import Swal from 'sweetalert2'
import moment from 'moment'
import _ from "lodash"
import store from '@/store/index.js'
import { snakeToCamel } from '@/js/utils'
import { getApiBaseUrl } from '@/js/functions'

// 排除在錯誤提示統一處理的api
const ErrorMsgDisableHandleArr = [
  'logout',
]

const apiBase = axios.create({
  baseURL: '/api/',
  withCredentials: false,
  headers: {
    'Accept': 'application/json',
    /**
     * 當Content-type為multipart/form-data，axios會自行幫data轉為formData()格式
     *
     * 須注意: 如果有設定transformRequest，則axios就不會幫忙轉換，需要自行轉成formData()
     */
    'Content-Type': 'multipart/form-data',
  },
  transformResponse: [
    function (res) {
      try {
        const parsed = JSON.parse(res)
        return parsed
      }
      catch (err) {
        return res
      }
    },
  ],
  validateStatus: function (status) {
    return status < 500 // Resolve only if the status code is less than 500
  }
})

apiBase.interceptors.request.use((config) => {
  // 發送前header參數
  if (store.state.auth.token)
    config.headers.Authorization = `Bearer ${store.state.auth.token}`

  return config
}, (error) => {
  // Do something with request error
  return Promise.reject(error)
})

apiBase.interceptors.response.use((config) => {

  return config
}, (error) => {
  if(axios.isCancel(error)){
    return Promise.reject('isCancel')
  }
  // Do something with request error
  if (error.config !== undefined) {
    if (!ErrorMsgDisableHandleArr.includes(error.config.url)) {
      if (error.response) {
        // server responded status code falls out of the range of 2xx
        switch (error.response.status) {
          case 400:
            Swal.fire({
              title: '訊息提示',
              text:  `${error.response.status}: 請求無效，請檢查您的輸入是否正確`,
              icon: 'info',
              confirmButtonText: '確定',
            }).then(() => {
            })
            break
          case 401:
            Swal.fire({
              title: '訊息提示',
              text:  `${error.response.status}: 您未被授權訪問此資源，請重新登入或聯繫管理員。`,
              icon: 'info',
              confirmButtonText: '確定',
            }).then(() => {
            })
            break
          case 403:
            Logout(true)
            break
          case 500:
            if (error.response.data && error.response.data.Message) {
              Swal.fire({
                title: '訊息提示',
                text: error.response.data.Message,
                icon: 'info',
                confirmButtonText: '確定',
              }).then(() => {
              })
            }
            else {
              Swal.fire({
                title: '訊息提示',
                text: `${error.response.status}: 內部系統發生錯誤`,
                icon: 'info',
                confirmButtonText: '確定',
              }).then(() => {
              })
            }
            break
          default:
            Swal.fire({
              title: '訊息提示',
              text: `${error.response.status}: 內部系統發生錯誤`,
              icon: 'info',
              confirmButtonText: '確定',
            }).then(() => {
            })
            break
        }
      }
      else {
        // Something happened in setting up the request that triggered an Error
        if (error.code === 'ECONNABORTED' && error.message && error.message.includes('timeout')) {
          // request time out will be here
          Swal.fire({
            title: '訊息提示',
            text: '網路連線逾時，請點「確認」鍵後繼續使用。',
            icon: 'info',
            confirmButtonText: '確定',
          }).then(() => {
          })
        }
        else {
          Swal.fire({
            title: '訊息提示',
            text: '發生錯誤，請稍候再試',
            icon: 'info',
            confirmButtonText: '確定',
          }).then(() => {
          })
        }
      }
    }
  }
  else {
    console.log('axios error', error)
  }

  // return error;
  return Promise.reject(error)
})


function setApiMode(apiBase, mode) {
  apiBase.defaults.baseURL = getApiBaseUrl()
  return apiBase
}

const checkPermissionApi = _.debounce(function () {
  if(!store.state.auth.isLogined) return
  apiBase.get('/auth/checkPermission').then((res) => {
    if (res.data.permission == store.state.auth.allPermission) return
    store.dispatch('auth/logout')
  })
}, 1000)

const passList = ['/auth/login', '/message/get', '/message/save', '/employee/dashboard_progress']
function checkPermission(url) {
  if (passList.includes(url)) return
    checkPermissionApi()
}


function baseGet(url, formInfo, mode) {
  checkPermission(url)
  return setApiMode(apiBase, mode).get(url, formInfo)
}

function basePost(url, formInfo, mode, set) {
  checkPermission(url)
  return setApiMode(apiBase, mode).post(url, formInfo, set)
}

function basePostDownload(url, formInfo, set) {
  return setApiMode(apiBase).post(url, formInfo, set)
}

// Upload
const Upload = {
  handle(files) {
    return basePost('/file/upload', _.fromPairs(_.concat([], files).map(row => ['files[]', row]))).then((res) => {
      return res.data
    })
  },
  base64(formData) {
    return basePost('/file/base64', formData).then((res) => {
      return res.data
    })
  },
  simpleUpload(formData) {
    return basePost('/file/simpleUpload', formData).then((res) => {
      return res.data
    })
  },
}

// Employee
const Employee = {
  simpleGet(params = {}){
    return basePost('/employee/', params).then((res) => {
      return res.data
    })
  },
  /** @param {GroupCode[]} limit_group_code */
  get(limit_group_code = [], current_branch = 1, obj) {
    return basePost('/employee/', {
      branch_id: current_branch ? store.state.auth.branchId : 0,
      limit_group_code,
      ...obj
    }).then((res) => {
      return res.data
    })
  },
  /** 醫生不依照分店撈，醫生沒有固定在哪間店的概念 */
  doctors(obj) {
    return Employee.get(['BR_DOC'], 0, obj).then((data) => {
      return data
    })
  },
  consults(newRules = false) {
    return Employee.get(
      newRules ? ['BR_ARO', 'BR_CON', 'BR_NUS','BR_MGA'] : ['BR_ARO', 'BR_CON', 'BR_NUS']
    ).then((data) => {
      return data
    })
  },
  employees(newRules = false) {
    return Employee.get(
      newRules ? ['BR_ARO', 'BR_CON', 'BR_NUS', 'BR_DOC', 'BR_MGA'] : ['BR_ARO', 'BR_CON', 'BR_NUS', 'BR_DOC'], 
      1).then((data) => {
      return data
    })
  },
  // 客服人員
  services(newRules = false) {
    return Employee.get(
      newRules ? 
        ['BR_ANG', 'BR_ARO', 'BR_CON', 'BR_NUS', 'BR_MAG', 'BR_MGA'] :
        ['BR_ANG', 'BR_ARO', 'BR_CON', 'BR_NUS', 'BR_MAG']
    ).then((data) => {
      return data
    })
  },
  employeeInBranch(obj) {
    return basePost('/employee/employeeInBranch', {
      branch_id: store.state.auth.branchId,
      limit_group_code: ['BR_ARO', 'BR_CON', 'BR_NUS', 'BR_MAG', 'BR_ANG', 'BR_MGA'],
      ...obj
    }).then((res) => {
      return res.data
    })
  },
  // 沒登入才用的人員列表
  employeeNoneIndex(obj) {
    return basePost('/employee/none_index', {
      branch_id: store.state.auth.branchId || 0,
      limit_group_code: ['BR_ARO', 'BR_CON', 'BR_NUS', 'BR_MGA'],
      ...obj
    }).then((res) => {
      return res.data
    })
  },
  /**
   * 待辦事項
   * @param {object} params
  */
  noticeList(params = {}) {
    return basePost('/employee/notice/list', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
     * @param {object} params
     * @param {string=} params.notice_date - (YYYY/MM/DD)
     * @param {string=} params.content
     * @return {Promise<object>} response
    */
  noticeAdd(params = {}) {
    return basePost('/employee/notice/add', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
     * @param {object} params
     * @param {number=} params.id
     * @return {Promise<object>}
    */
  noticeRemove(params = {}) {
    return basePost('/employee/notice/remove', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
     * @param {object} params
     * @param {number=} params.id
     * @param {string=} params.notice_date
     * @param {string=} params.content
     * @return {Promise<object>}
    */
  noticeUpdate(params = {}) {
    return basePost('/employee/notice/update', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  pt(obj) {
    return basePost('/employee/pt ', {
      branch_id: store.state.auth.branchId,
      limit_group_code: ['BR_ARO', 'BR_CON', 'BR_NUS', 'BR_MAG', 'BR_ANG'],
      ...obj
    }).then((res) => {
      return res.data
    })
  },
}

// Order
const Order = {
  hints(params = {}) {
    return basePost('/order/hints ', params).then((res) => {
      return res.data
    })
  },
  create(params = {}) {
    return basePost('/order/create', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  update(params = {}) {
    return basePost('/order/update', JSON.stringify({
      branch_id: store.state.auth.branchId,
      ...params,
    }), null, { 
      headers: { 'Content-Type': 'application/json' }
    }).then((res) => {
      return res.data
    })
  },
  /**
   * 刪除預約
   * @param {object} params
   * @param {number=} params.order_id - ()
   *
   * @return {Promise<object>} response
  */
  delete(params = {}) {
    return basePost('/order/delete', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * 刪除預約
   * @param {object} params
   *
  */
  get(params = {}, set) {
    return basePost('/order/', {
      // 康博才要顯示房間
      show_room: store.getters['auth/isCome'] ? 1 : null,
      branch_id: store.state.auth.branchId,
      ...params,
    }, null, set).then((res) => {
      return res.data
    })
  },
  // calendar-search 頁匯出
  export(params = {}) {
    return basePost('/order/export', {
      // 康博才要顯示房間
      show_room: store.getters['auth/isCome'] ? 1 : null,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  statistics(params = {}, set) {
    return basePost('/order/statistics', {
      // 康博才要顯示房間
      show_room: store.getters['auth/isCome'] ? 1 : null,
      branch_id: store.state.auth.branchId,
      ...params,
    }, null, set).then((res) => {
      return res.data
    })
  },
  info(params = {}) {
    return basePost('/order/info', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  statusList() {
    return basePost('/order/statusList').then((res) => {
      return res.data
    })
  },
  consultSave(params = {}) {
    return basePost('/order/consultation/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  consultInfo(params = {}) {
    return basePost('/order/consultation/info', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  consultOptions() {
    return basePost('/order/consultation/options', {
      branch_id: store.state.auth.branchId,
    }).then((res) => {
      return res.data
    })
  },
  doctorRecordOptions(params = {}) {
    return basePost('/order/doctorRecord/options', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  doctorRecordInfo(params = {}) {
    return basePost('/order/doctorRecord/info', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  doctorRecordSave(params = {}) {
    return basePost('/order/doctorRecord/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
     * @param {object} params
     * @param {string|string[]=} params.lock_date - ('2022-01-01')
     * @param {number} [params.branch_id]
     * @param {string=} params.start_at
     * @param {string=} params.end_at
     * @param {number=} params.operator_employee_id
     * @param {string=} params.keyword
     * 
     *
     * @return {Promise<Array<{
     *  id: number,
     *  lock_date: string,
     *  start_at_format: string,
     *  end_at_format: string,
     *  lock_target: 0 | 1 | 2 | 3,
     *  lock_employees: Array,
     *  memo: string,
     * }>>} response
    */
  lockList(params = {}, set) {
    return basePost('/order/lock/list', {
      brand_id: store.getters['auth/isCome'] ? 1 : null,
      branch_id: store.state.auth.branchId,
      ...params,
    }, null, set).then((res) => {
      return res.data
    })
  },
  /**
     * @param {object} params
     * @param {number} [params.branch_id]
     * @param {string=} params.lock_date - ('2022/01/01')
     * @param {string=} params.start_at - (datetime)
     * @param {string=} params.end_at - (datetime)
     *
     * @return {Promise<object>} response
    */
  //  要棄用了
  lockAdd(params = {}) {
    return basePost('/order/lock/add', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * @param {object} params
   * @param {number=} params.branch_id
   * @param {string=} params.lock_date - ('2022/01/01')
   * @param {string=} params.start_at - (datetime)
   * @param {string=} params.end_at - (datetime)
   * @param {string=} params.memo
   * @param {number=} params.lock_target - (0:全部員工、1:特定員工、2:全部房間、3:特定房間 )
   * @param {number[]=} params.employee
   * @param {number=} params.id - (編輯時需要)
   *
   * @return {Promise<object>} response
  */
  lockSave(params = {}) {
    return basePost('/order/lock/save', {
      brand_id: store.getters['auth/isCome'] ? 1 : null,
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * @param {object} params
   * @param {number=} params.branch_id
   * @param {number=} params.id
   * @return {Promise<object>} response
  */
  lockInfo(params = {}) {
    return basePost('/order/lock/info', {
      brand_id: store.getters['auth/isCome'] ? 1 : null,
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
     * @function
     * @param {object} params
     * @property {number} id
    */
  lockRemove(params = {}) {
    return basePost('/order/lock/remove', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * @param {object} params
   * @param {string|string[]=} params.lock_date - ('2022-01-01')
   * @param {number} [params.branch_id]
   * @param {string=} params.start_at
   * @param {string=} params.end_at
   * @param {string=} params.keyword
   * @param {string=} params.consult_id
   */
  stickyList(params = {}, set) {
    return basePost('/order/sticky', {
      branch_id: store.state.auth.branchId,
      ...params,
    }, null, set).then((res) => {
      return res.data
    })
  },
  /**
   * @param {object} params
   * @param {number=} params.id
   * @return {Promise<object>} response
  */
  stickyInfo(params = {}) {
    return basePost('/order/sticky/info', {
      // branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * @param {object} params
   * @param {number=} params.consult_id
   * @param {string=} params.order_date - (datetime)
   * @param {number=} params.id - (編輯時需要)
   * @param {string=} params.start_at - (datetime)
   * @param {string=} params.end_at - (datetime)
   * @param {string=} params.content
   * @param {string=} params.memo
   * @return {Promise<object>} response
  */
  stickySave(params = {}) {
    return basePost('/order/sticky/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * @param {object} params
   * @param {number=} params.id
   * @return {Promise<object>} response
  */
  stickyRemove(params = {}) {
    return basePost('/order/sticky/remove', {
      // branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  // 施作項目主檔及其課程
  treatmentInfo(params = {}) {
    return basePost('/order/treatment/info', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  // 施作項目主檔儲存
  treatmentSave(params = {}) {
    return basePost('/order/treatment/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  // 施作項目項目儲存數量
  treatmentItemCreate(params = {}) {
    return basePost('/order/treatment/item/create', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  // 施作項目項目儲存數量
  treatmentItemSave(params = {}) {
    return basePost('/order/treatment/item/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  // 施作項目項目刪除單筆
  treatmentItemRemove(params = {}) {
    return basePost('/order/treatment/item/remove', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * 施作項目備註儲存
   * @param {object} params 
   * @param {number=} params.item_id 
   * @param {number[]=} params.note_ids 
   * @returns 
   */
  treatmentNoteSave(params = {}) {
    return basePost('/order/treatment/item/note/save', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  // 施作項目分，不一定有
  treatmentCategoryInfo(params = {}) {
    return basePost('/order/treatment/category/info', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  checkList(params = {}) {
    return basePost('/order/checkList', params).then((res) => {
      return res.data
    })
  },
  checkListSave(params = {}) {
    return basePost('/order/checkList/save', params).then((res) => {
      return res.data
    })
  },
  treatmentCheckList(params = {}) {
    return basePost('/order/treatment/checkList', params).then((res) => {
      return res.data
    })
  },
  sendNewCustomer(params = {}) {
    return basePost('/order/sendNewCustomer ', params).then((res) => {
      return res.data
    })
  },
  contentHints(params = {}) {
    return basePost('/order/contentHints ', params).then((res) => {
      return res.data
    })
  },
  batchCreateValidate(params = {}) {
    return basePost('/order/batch_create_validate', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  batchCreate(params = {}) {
    return basePost('/order/batch_create', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
}

const Room = {
  get(params = {}) {
    return basePost('/order/treatment/consumable/room', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  statusUpdate(params = {}) {
    return basePost('/order/treatment/consumable/room/update', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  roomBedUpdate(params = {}) {
    return basePost('/order/treatment/consumable/roomBed/update', {
      ...params,
    }).then((res) => {
      return res.data
    })
  }
}

const Consumable = {
  get(params = {}) {
    return basePost('/order/treatment/consumable/info', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  getHints(params = {}) {
    return basePost('/order/treatment/consumable/hints', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  save(params = {}) {
    return basePost('/order/treatment/consumable/save', JSON.stringify({
      ...params,
    }), null, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((res) => {
      return res.data
    })
  },
  saveTemp(params = {}) {
    return basePost('/order/treatment/consumable/saveTemp', JSON.stringify({
      ...params,
    }), null, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((res) => {
      return res.data
    })
  },
  itemSave(params = {}) {
    return basePost('/order/treatment/consumable/ItemSave', JSON.stringify({
      ...params,
    }), null, {
      headers: {
        'Content-Type': 'application/json',
      }
    }).then((res) => {
      return res.data
    })
  },
  getConsumable(params = {}) {
    return basePost('/order/treatment/consumable', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  ship(params = {}) {
    return basePost('/order/treatment/consumable/ship', {
      ...params,
    }).then((res) => {
      return res.data
    })
  }
}

// CustomerRecord
const CustomerRecord = {
  info(params = {}) {
    return basePost('/customer/record/info/', params).then((res) => {
      return res.data
    })
  },
  save(params = {}) {
    return basePost('/customer/record/save/', params).then((res) => {
      return res.data
    })
  },
}

// Pos
const Pos = {
  handle(url, formData, set) {
    return basePost('pos', {
      url: `api/${url}`,
      ...formData,
    }, null, set).then((res) => {
      return res
    })
  },
  uri() {
    return baseGet('pos').then((res) => {
      return res.data
    })
  },
}

// Branch
const Branch = {
  info(id = 0) {
    return basePost(`/branch/info/${id}`).then((res) => {
      return res.data
    })
  }
}

const Brand = {
  getAnnouncement() {
    const brandId = store.getters['auth/isCome'] ? 1 : 2
    return baseGet(`/brand/announcement/${brandId}`).then((res) => {
      return res.data
    })
  },
  setAnnouncement(params = {}) {
    const brandId = store.getters['auth/isCome'] ? 1 : 2
    return basePost(`/brand/announcement_save/${brandId}`, {
      ...params
    }).then((res) => {
      return res.data
    })
  }
}

// Post
const Post = {
  get(params = {}) {
    return basePost('/post/', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  read(params = {}) {
    return basePost('/post/read', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * 更新已讀狀態
   * @param {object} params 
   * @param {number=} params.readed - (0:取消已讀、 1:已讀)
   * @param {number=} params.post_id - ()
   * @returns 
   */
  readUpdate(params = {}) {
    return basePost('/post/readUpdate', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  detail(params = {}) {
    return basePost('/post/detail', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  comment(params = {}) {
    return basePost('/post/comment', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  readStatus(params = {}) {
    return basePost('/post/readStatus', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  save(params = {}) {
    return basePost('/post/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  /**
   * 未讀訊息數字
   * @param {*} params 
   * @returns 
   */
  getNum(params = {}) {
    return basePost('/post/getNum', {
      branch_id: store.state.auth.branchId,
      employee_id: store.state.auth.employeeId
    })
  },
}

// Customer
const Customer = {
  get(params = {}) {
    return Pos.handle('GetMemberBase', params).then((res) => {
      return res.data
    })
  },
  tags(params = {}) {
    return basePost('/customer/tags', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  messageSetting(params = {}) {
    return basePost('/customer/message/setting', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  messageSettingSave(params = {}) {
    return basePost('/customer/message/setting/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  doctorRecords(params = {}) {
    return basePost('/customer/doctor/records', {
      branch_id: store.state.auth.branchId,
      ...params,
    })
  },
  /**
     * @param {object} params
     * @param {string=} params.record_id
     * @param {string=} params.customer_code
    */
  doctorRecordCreate(params = {}) {
    return basePost('/customer/doctor/record/create', {
      branch_id: store.state.auth.branchId,
      order_id: 0,
      record_at: moment().format('YYYY/MM/DD'),
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
     * @param {object} params
     * @param {string=} params.id
     * @param {string=} params.record_id
     * @param {string=} params.customer_code
     * @param {array=} params.medias
     * @param {string=} params.record_at - (YYYY/MM/DD)
     *
     * @return {Promise<object[]>} - (最新的資料)
    */
  doctorRecordSave(params = {}) {
    return basePost('/customer/doctor/record/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
     * @param {object} params
     * @param {string=} params.id
     * @param {string=} params.customer_code
     *
     * @return {Promise<object[]>} - (最新的資料)
    */
  doctorRecordRemove(params = {}) {
    return basePost('/customer/doctor/record/remove', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
 * @param {object} params
 * @param {string=} params.id
 * @param {string=} params.customer_code
 * @param {string=} params.sign_at - (2023-04-19 15:34:00)
 *
 * @return {Promise<object[]>} - (最新的資料)
*/
  treatmentRecordSave(params = {}) {
    return basePost('/customer/treatment/record/save', {
      branch_id: store.state.auth.branchId,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
}

// CustomerCard
const CustomerCard = {
  info(params = {}) {
    return basePost('/card/info', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  save(params = {}) {
    return basePost('/card/save', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  avatar(params = {}) {
    return basePost('/card/avatar', params).then((res) => {
      return res.data
    })
  },
  avatarSave(params = {}) {
    return basePost('/card/avatar/save', params).then((res) => {
      return res.data
    })
  },
  avatarRemove(params = {}) {
    return basePost('/card/avatar/remove', params).then((res) => {
      return res.data
    })
  },
  checkSign(params = {}) {
    return basePost('/card/checkSign', params).then((res) => {
      return res.data
    })
  },
}

const CustomerNone = {
  info(params = {}) {
    return basePost('/customerNone/info', {
      ...params,
    }).then((res) => {
      return res.data
    })
  },
}

const Consent = {
  sign(params = {}) {
    return basePost('/consent/sign', params).then((res) => {
      return res.data
    })
  },
  list(params = {}) {
    return basePost('/consent', params).then((res) => {
      return res.data
    })
  },
  add(params = {}) {
    return basePost('/consent/add', params).then((res) => {
      return res.data
    })
  },
  all(params = {}) {
    return basePost('/consent/all', {
      page: 1,
      perPage: 200,
      ...params,
    }).then((res) => {
      return res.data
    })
  },
  /**
   * 刪除
   * @param {*} params 
   * @property {*} params.id 
   * @returns 
   */
  signDestroy(params = {}) {
    return basePost('/consent/signDestroy', params).then((res) => {
      return res.data
    })
  },
}

const Schedule = {
  doctor: {
    diagnosis() {
      return basePost('/schedule/diagnosis', {
        branch_id: store.state.auth.branchId || 0
      }).then((res) => {
        return res.data
      })
    },
    /**
     * 取得醫生班表
     * @param {string} search_month 
     * @returns {Promise}
     */
    doctor(search_month) {
      return basePost('/schedule/doctor', {
        branch_id: store.state.auth.branchId || 0,
        search_month: search_month 
      }).then((res) => {
        return res.data
      })
    },
    /** 
     * 新增/編輯醫生班表
     * @param {object} params
     * @param {number} params.doctor_id 醫生id
     * @param {number} params.diagnosis_id 診間id
     * @param {string} params.schedule_at 排診
     * @param {string} params.start_at 開始時間
     * @param {string} params.end_at 結束時間
     * @param {number=} params.id 編輯時需要
     */
    save(params) {
      return basePost('/schedule/doctor/save', {
        branch_id: store.state.auth.branchId || 0,
        ...params
      }).then((res) => {
        return res.data
      })
    },
    /** 
     * 刪除醫生班表
     * @param {number} id 醫生id
     */
    delete(id) {
      return basePost('/schedule/doctor/delete', {
        id
      }).then((res) => {
        return res.data
      })
    },
  },
  employee:  {
    /** 
     * 假別
     */
    leaves() {
      return basePost('/schedule/leaves', {
        branch_id: store.state.auth.branchId || 0
      }).then((res) => {
        return res.data
      })
    },
    /** 
     * 班別
     */
    shifts() {
      return basePost('/schedule/shifts', {
        branch_id: store.state.auth.branchId || 0
      }).then((res) => {
        return res.data
      })
    },
    /** 
     * 員工班表 - 月曆內容
     * @param {string} search_month 
     * @returns {Promise}
     */
    employee(search_month) {
      return basePost('/schedule/employee', {
        branch_id: store.state.auth.branchId || 0,
        search_month: search_month 
      }).then((res) => {
        return res.data
      })
    },
    /** 
     * 新增/編輯員工班表
     * @param {object} params
     * @param {string} params.roster_date 排班日期
     * @param {number} params.to_employee_id 員工id
     * @param {number=} params.leave_id 假別id
     * @param {number=} params.shift_id 班別id
     * @param {string=} params.start_at 開始時間
     * @param {string=} params.end_at 結束時間
     * @param {number=} params.support_branch_id
     * @param {number=} params.id 編輯時需要
     */
    // save(params) {
    //   return basePost('/schedule/employee/save', {
    //     branch_id: store.state.auth.branchId || 0,
    //     ...params
    //   }).then((res) => {
    //     return res.data
    //   })
    // },
    /** 
     * 批次新增員工班表
     * @param {object} params
     * @param {string} params.search_month 排班日期
     * @param {number} params.to_employee_id 員工id
     * @param {number=} params.leave_id 假別id
     * @param {number=} params.shift_id 班別id
     */
    // saveAll(params) {
    //   return basePost('/schedule/employee/multiple_save', {
    //     branch_id: store.state.auth.branchId || 0,
    //     ...params
    //   }).then((res) => {
    //     return res.data
    //   })
    // },
    /** 
     * 刪除員工班表
     * @param {number} id
     * @returns {Promise}
     */
    // delete(id) {
    //   return basePost('/schedule/employee/delete', {
    //     id
    //   }).then((res) => {
    //     return res.data
    //   })
    // },
    /** 
     * 編輯上月結算
     * @param {object} params
     * @returns {Promise}
     */
    update(params) {
      return basePost('/schedule/employee/ajax_employee_update', {
        ...params
      }).then((res) => {
        return res.data
      })
    },
    rosterUpdate(params) {
      return basePost('/schedule/employee/ajax_roster_update', {
        ...params
      }).then((res) => {
        return res.data
      })
    },
    export(params) {
      return basePostDownload('/schedule/employee/schedule_export', {
        ...params
      }, {
        responseType: 'blob'
      }).then((res) => {
        return res
      })
    },
    
  },
  resync(params) {
    return baseGet('/schedule/resync', {
      params: {
        ...params
      }
    }).then((res) => {
      return res.data
    })
  },
}

const Message = {
  get (params = {}) {
    return basePost('/message/get', params).then((res) => {
      return res.data
    })
  },
  save (params = {}) {
    return basePost('/message/save', params).then((res) => {
      return res.data
    })
  }
}

// Encrypt
const Encrypt = {
  encrypt(params = {}) {
    return basePost('/encrypt', params).then((res) => {
      return res.data
    })
  },
  decrypt(params = {}) {
    return basePost('/decrypt', params).then((res) => {
      return res.data
    })
  },
}

function Login(formInfo) {
  return basePost('auth/login', formInfo).then((response) => {
    if ( typeof response.data === 'string' ) {
      appSwalCustomDialog.open({
        title: response.data,
        swal: {
          timer: 1000,
        },
      })
      return
    } 
    store.dispatch('auth/login', snakeToCamel(response.data)).then(() => {
      appSwalCustomDialog.open({
        title: '登入成功',
        swal: {
          timer: 1000,
        },
      }).then(() => {
        //@ts-ignore
        window.location = '/'
      })
    })
  })
}

function Logout(silent = false) {
  store.dispatch('auth/logout')
  if (!silent) {
    store.commit('setData', {
      delayedAction: {
        data: {
          msg: '登出成功',
        },
      },
    })
    // 不管如何，總是登出
    //@ts-ignore
    window.location = '/'
  }
  else {
    // 不管如何，總是登出
    //@ts-ignore
    window.location = '/'
  }
}

export {
  getApiBaseUrl,
  apiBase,
  baseGet,
  basePost,
  basePostDownload,

  // ==== API ====
  CustomerRecord,
  CustomerCard,
  Employee,
  Customer,
  Consent,
  Branch,
  Brand,
  Upload,
  Order,
  Post,
  Pos,
  Schedule,
  Message,
  Encrypt,
  Room,
  Consumable,
  CustomerNone,

  // ==== Wrapped API ====
  // account
  Login,
  Logout,
}
